import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Loading from "../components/Loading";
import Header from "../components/Header";

const SignUpPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [className, setClassName] = useState("");
  const [fullName, setFullName] = useState("");
  const [school, setSchool] = useState(0);
  const [schoolNo, setSchoolNo] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [schools, setSchools] = useState([]);

  useEffect(() => {
    fetch("/api/schools")
      .then((res) => res.json())
      .then((data) => {
        setSchools(data);
        setIsLoading(false);
      });
  }, []);

  const handleSubmit = () => {
    fetch("/api/auth/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
        fullName: fullName,
        school: school,
        className: className,
        schoolNo: schoolNo,
      }),
    }).then((res) => {
      if (res.status === 200) {
        alert("Başarıyla kayıt oldunuz.");
        window.location.href = "/";
      } else {
        alert("Bir hata oluştu.");
      }
    });
  };

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <Header />
          <div className="container" style={{ textAlign: "center" }}>
            <div className="table-responsive">
              <table className="table">
                <tbody>
                  <tr>
                    <td>İsim Soyisim</td>
                    <td>
                      <input
                        type="text"
                        onChange={(e) => setFullName(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Kullanıcı Adı</td>
                    <td>
                      <input
                        type="text"
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Şifre</td>
                    <td>
                      <input
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Okul</td>
                    <td>
                      <select
                        about="Okul"
                        onChange={(e) => setSchool(e.target.value)}
                      >
                        <option value="0" selected>
                          Okul Seçiniz
                        </option>
                        {schools.map((school, index) => (
                          <option key={index} value={school.id}>
                            {school.name}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Sınıf</td>
                    <td>
                      <input
                        type="text"
                        onChange={(e) => setClassName(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Okul No</td>
                    <td>
                      <input
                        type="text"
                        onChange={(e) => setSchoolNo(e.target.value)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <button
                className="btn btn-primary"
                type="button"
                onClick={handleSubmit}
              >
                Kayıt Ol
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignUpPage;
