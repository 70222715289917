import "./App.css";
import React, { useEffect, useState } from "react";
import Loading from "./components/Loading";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AdminPage from "./pages/admin/AdminPage";
import AdminChatPage from "./pages/admin/AdminChatPage";
import AdminScaleDetailed from "./pages/admin/AdminScaleDetailed";
import Survey from "./pages/Survey";
import ChatPage from "./pages/ChatPage";
import LoginPage from "./pages/LoginPage";
import AkademikGelisim from "./pages/Kategori/AkademikGelisim";
import KariyerGelisimi from "./pages/Kategori/KariyerGelisimi";
import SosyalDuygusalGelisim from "./pages/Kategori/SosyalDuygusalGelisim";
import AdminStudentSelect from "./pages/admin/AdminStudentSelect";
import SignUpPage from "./pages/SignUpPages";

function App() {
  const [isSigned, setIsSigned] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    fetch("/api/auth/session", {
      credentials: "same-origin",
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) return res.json();
        setIsSigned(false);
        return null;
      })
      .then((data) => {
        console.log(data);
        if (data != null) {
          setIsSigned(true);
          if (data.role === "ADMIN") {
            setIsAdmin(true);
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  return (
    <>
      {isSigned == null ? (
        <Loading />
      ) : (
        <Router>
          {isSigned ? (
            isAdmin ? (
              <Routes>
                <Route path="/" element={<AdminPage />} />
                <Route path="/admin" element={<AdminPage />} />
                <Route path="/admin/answers" element={<AdminStudentSelect />} />
                <Route
                  path="/admin/answers/:answerId"
                  element={<AdminScaleDetailed />}
                />
                <Route path="/admin/chat" element={<AdminChatPage />} />
              </Routes>
            ) : (
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/akademik-gelisim" element={<AkademikGelisim />} />
                <Route path="/kariyer-gelisimi" element={<KariyerGelisimi />} />
                <Route
                  path="/sosyal-duygusal-gelisim"
                  element={<SosyalDuygusalGelisim />}
                />
                <Route path="/olcek/:olcekId" element={<Survey />} />
                <Route path="/chat" element={<ChatPage />} />
              </Routes>
            )
          ) : (
            <Routes>
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/*" element={<LoginPage />} />
            </Routes>
          )}
        </Router>
      )}
    </>
  );
}

export default App;
