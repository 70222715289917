import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../components/Loading";
import Header from "../components/Header";

const Survey = () => {
  const { olcekId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [scale, setScale] = useState([]);
  const [answers, setAnswers] = useState([]);

  const handleSubmit = () => {
    fetch("/api/scales/" + olcekId + "/answers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(answers),
    }).then((res) => {
      if (res.status === 200) {
        alert("Başarıyla gönderildi.");
        window.location.href = "/";
      } else {
        alert("Bir hata oluştu.");
      }
    });
  };

  const createJsonObject = (aId, qId) => {
    const selectedAnswer = {
      questionId: qId,
      answerId: aId,
    };
    const index = answers.findIndex((a) => a.questionId === qId);
    if (index !== -1) {
      const updatedAnswers = [...answers];
      updatedAnswers[index] = selectedAnswer;
      setAnswers(updatedAnswers);
    } else {
      setAnswers([...answers, selectedAnswer]);
    }
  };

  useEffect(() => {
    fetch("/api/scales/" + olcekId)
      .then((res) => res.json())
      .then((data) => {
        setScale(data);
        console.log(data);
        setIsLoading(false);
      });
  }, [olcekId]);

  return (
    <div>
      <>
        {isLoading ? (
          <Loading />
        ) : (
          <div>
            <Header />
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Soru</th>
                    {scale.questionAnswers.map((answer, index2) => (
                      <th>{answer.text}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {scale.questions.map((question, index) => (
                    <tr>
                      <td>{question.no}</td>
                      <td>{question.text}</td>
                      {scale.questionAnswers.map((answer, index2) => (
                        <td style={{ textAlign: "center", cursor: "pointer" }}>
                          <input
                            type="radio"
                            name={question.text}
                            value={answer.text}
                            onChange={(e) =>
                              createJsonObject(answer.id, question.no)
                            }
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div style={{ textAlign: "right" }}>
                <button onClick={handleSubmit} className="btn btn-primary">
                  Gönder
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default Survey;
