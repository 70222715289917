import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/Header";
import Mesleki from "../../assets/img/olcek/mesleki-ilgi.jpeg";

const KariyerGelisimi = () => {
  return (
    <div>
      <Header />
      <div className="container py-4 py-xl-5">
        <div className="row mb-5">
          <div className="col-md-8 col-xl-6 text-center mx-auto">
            <h2>Lütfen Bir Ölçek Seçiniz</h2>
          </div>
        </div>
        <div className="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3">
          <div className="col">
            <a href="/olcek/3">
              <div>
                <img
                  className="rounded img-fluid d-block w-100 fit-cover"
                  style={{ height: "200px" }}
                  alt="Kariyer kaygısı ölçeği"
                  // src="academic_development.jpeg"
                />
                <div className="py-4">
                  <h4 style={{ textAlign: "center" }}>
                    Kariyer kaygısı ölçeği
                  </h4>
                </div>
              </div>
            </a>
          </div>
          <div className="col">
            <a href="/olcek/4">
              <div>
                <img
                  className="rounded img-fluid d-block w-100 fit-cover"
                  style={{ height: "200px" }}
                  src={Mesleki}
                  alt="Mesleki ilgi ölçeği"
                />
                <div className="py-4">
                  <h4 style={{ textAlign: "center" }}>Mesleki ilgi ölçeği</h4>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KariyerGelisimi;
