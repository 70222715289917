import React from "react";
import Header from "../components/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import AcademicDevelopment from "../assets/img/academic_development.jpeg";
import CareerDevelopment from "../assets/img/career_development.jpeg";
import EmotionalDevelopment from "../assets/img/emotional_development.jpeg";
import OnlinePDR from "../assets/img/online_pdr.jpeg";
import "../assets/css/styles.min.css";

const HomePage = () => {
  return (
    <div style={{ background: "var(--bs-gray-900);" }}>
      <Header />
      <div className="container py-4 py-xl-5">
        <div className="row mb-5">
          <div className="col-md-8 col-xl-6 text-center mx-auto">
            <h2>Lütfen Bir Kategori Seçiniz</h2>
          </div>
        </div>
        <div className="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-2">
          <div className="col">
            <Link style={{ textDecoration: "none" }} to="/kariyer-gelisimi">
              <div>
                <img
                  className="rounded img-fluid d-block w-100 fit-cover"
                  style={{ height: "200px" }}
                  src={CareerDevelopment}
                  alt="Kariyer Gelişimi"
                />
                <div
                  className="py-4"
                  style={{
                    background: "yellow",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  <h4>Kariyer Gelişimi</h4>
                </div>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link style={{ textDecoration: "none" }} to="/akademik-gelisim">
              <div>
                <img
                  className="rounded img-fluid d-block w-100 fit-cover"
                  style={{ height: "200px" }}
                  src={AcademicDevelopment}
                  alt="Akademik Gelişim"
                />
                <div
                  style={{
                    background: "green",
                    color: "black",
                    textAlign: "center",
                  }}
                  className="py-4"
                >
                  <h4>Akademik Gelişim</h4>
                </div>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link
              style={{ textDecoration: "none" }}
              to="/sosyal-duygusal-gelisim"
            >
              <div>
                <img
                  className="rounded img-fluid d-block w-100 fit-cover"
                  style={{ height: "200px" }}
                  src={EmotionalDevelopment}
                  alt="Sosyal, Duygusal Gelişim"
                />
                <div
                  className="py-4"
                  style={{
                    background: "blue",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  <h4>Sosyal, Duygusal Gelişim</h4>
                </div>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link style={{ textDecoration: "none" }} to="/chat">
              <div>
                <img
                  className="rounded img-fluid d-block w-100 fit-cover"
                  style={{
                    height: "200px",
                  }}
                  src={OnlinePDR}
                  alt="Çevrimiçi Psikolojik Danışma"
                />
                <div
                  className="py-4"
                  style={{
                    background: "red",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  <h4 style={{ fontStretch: "ultra-expanded" }}>
                    Çevrimiçi Psikolojik Danışma
                  </h4>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
