import React from 'react';

const ChatPage = () => {
  return (
    <div>
      <h1>ChatPage</h1>
      {/* Add your chat UI components here */}
    </div>
  );
};

export default ChatPage;
