import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import OlcekXd from "../../assets/img/admin-olcekler.jpeg";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import OnlinePDR from "../../assets/img/online_pdr.jpeg";

const AdminPage = () => {
  return (
    <div>
      <Header />
      <div className="container py-4 py-xl-5">
        <div className="row mb-5">
          <div className="col-md-8 col-xl-6 text-center mx-auto">
            <h2>Admin Page</h2>
          </div>
        </div>
        <div className="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3">
          <div className="col">
            <Link to="/admin/answers">
              <img
                className="rounded img-fluid d-block w-100 fit-cover"
                style={{ height: "200px" }}
                src={OlcekXd}
                alt="Öğrenci Anketleri"
              />
              <div className="py-4">
                <h4 style={{ textAlign: "center" }}>Öğrenci Anketleri</h4>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link to="admin/chat">
              <img
                className="rounded img-fluid d-block w-100 fit-cover"
                style={{ height: "200px" }}
                src={OnlinePDR}
                alt="Chat"
              />
              <div className="py-4">
                <h4 style={{ textAlign: "center" }}>Chat</h4>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
