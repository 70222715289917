import React from "react";
import "../assets/css/Loading.css";

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="loading-spinner"></div>
      <p className="loading-text">Yükleniyor...</p>
    </div>
  );
};

export default Loading;
