import React from "react";
import Logo from "../assets/img/logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/styles.min.css";

function Header() {
  return (
    <nav className="navbar navbar-dark navbar-expand-md bg-dark text-center d-lg-flex justify-content-center align-items-center align-content-center py-3">
      <div className="container">
        <button
          data-bs-toggle="collapse"
          className="navbar-toggler"
          data-bs-target="#navcol-6"
        >
          <span className="visually-hidden">Toggle navigation</span>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse flex-grow-0 order-md-first"
          id="navcol-6"
        >
          <ul className="navbar-nav me-auto"></ul>
          <div className="d-md-none my-2">
            <button className="btn btn-light me-2" type="Yavuz">
              Button
            </button>
            <button className="btn btn-primary" type="button">
              Button
            </button>
          </div>
        </div>
        <a href="/">
          <img
            width="265"
            height="171"
            src={Logo}
            style={{ width: "246px" }}
            alt="Anasayfa"
          />
        </a>
      </div>
    </nav>
  );
}

export default Header;
