import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/Header";
import Loading from "../../components/Loading";

const AdminStudentSelect = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    fetch("/api/admin/answers")
      .then((res) => res.json())
      .then((data) => {
        setAnswers(data);
        console.log(data);
        setIsLoading(false);
      });
  }, []);

  const onClickEvent = (answerId) => {
    console.log("TIklandı" + answerId);
    window.location.href = "/admin/answers/" + answerId;
    //REDirect /admin/answers/{answerId}
  };

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <Header />
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Öğrenci Adı</th>
                  <th>Öğrenci Sınıf</th>
                  <th>Ölçek</th>
                  <th>Puan</th>
                  <th>Detay</th>
                </tr>
              </thead>
              <tbody>
                {answers.map((answer, index) => (
                  <tr id={index}>
                    <th>{answer.id}</th>
                    <th>{answer.user.fullName}</th>
                    <th>{answer.user.schoolNo}</th>
                    <th>{answer.scale.text}</th>
                    <th>{answer.score}</th>
                    <th>
                      <input
                        type="button"
                        value="Detay"
                        onClick={() => onClickEvent(answer.id)}
                      />
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminStudentSelect;
