import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import Header from "../../components/Header";

const AdminScaleDetailed = () => {
  const { answerId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    fetch("/api/admin/answers/" + answerId)
      .then((res) => res.json())
      .then((data) => {
        setAnswers(data);
        console.log(data);
        setIsLoading(false);
      });
  }, [answerId]);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <Header />
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Soru</th>
                  <th>Cevap</th>
                </tr>
              </thead>
              <tbody>
                {answers.map((answer, index) => (
                  <tr id={index}>
                    <th>{answer.question.no}</th>
                    <th>{answer.question.text}</th>
                    <th>{answer.answer.text}</th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminScaleDetailed;
