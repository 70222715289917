import React from 'react';

const AdminChatPage = () => {
  return (
    <div>
      <h1>Admin Chat Page</h1>
      {/* Add your chat component here */}
    </div>
  );
};

export default AdminChatPage;
