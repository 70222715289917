import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/Header";
import GencPsikolojikSaglamlik from "../../assets/img/olcek/genc-psikolojik-saglamlik.jpeg";
import SosyalMedya from "../../assets/img/olcek/Sosyal-medya.jpeg";
import OkulaYonelikTutum from "../../assets/img/olcek/okula-yonelik-tutum.jpeg";
import OkulAidiyet from "../../assets/img/olcek/okul-aidiyet.jpeg";
import GencIcsellestirilmis from "../../assets/img/olcek/genc-icsellestirilmis.jpeg";
import GencDissallastirilmis from "../../assets/img/olcek/genc-dissallastirilmis-davranis.jpeg";

const SosyalDuygusalGelisim = () => {
  return (
    <div>
      <Header />
      <div className="container py-4 py-xl-5">
        <div className="row mb-5">
          <div className="col-md-8 col-xl-6 text-center mx-auto">
            <h2>Lütfen Bir Ölçek Seçiniz</h2>
          </div>
        </div>
        <div className="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3">
          <div className="col">
            <a href="/olcek/5">
              <div>
                <img
                  className="rounded img-fluid d-block w-100 fit-cover"
                  style={{ height: "200px" }}
                  src="academic_development.jpeg"
                  alt="Akran zorbalığı belirleme ölçeği"
                />
                <div className="py-4">
                  <h4 style={{ textAlign: "center" }}>
                    Akran zorbalığı belirleme ölçeği
                  </h4>
                </div>
              </div>
            </a>
          </div>
          <div className="col">
            <a href="/olcek/6">
              <div>
                <img
                  className="rounded img-fluid d-block w-100 fit-cover"
                  style={{ height: "200px" }}
                  src={GencPsikolojikSaglamlik}
                  alt="Çocuk ve genç psikolojik sağlamlık ölçeği"
                />
                <div className="py-4">
                  <h4 style={{ textAlign: "center" }}>
                    Çocuk ve genç psikolojik sağlamlık ölçeği
                  </h4>
                </div>
              </div>
            </a>
          </div>
          <div className="col">
            <a href="/olcek/7">
              <div>
                <img
                  className="rounded img-fluid d-block w-100 fit-cover"
                  style={{ height: "200px" }}
                  src={SosyalMedya}
                  alt="Sosyal medya bağımlılığı ölçeği"
                />
                <div className="py-4">
                  <h4 style={{ textAlign: "center" }}>
                    Sosyal medya bağımlılığı ölçeği
                  </h4>
                </div>
              </div>
            </a>
          </div>
          <div className="col">
            <a href="/olcek/8">
              <div>
                <img
                  className="rounded img-fluid d-block w-100 fit-cover"
                  style={{ height: "200px" }}
                  src={OkulaYonelikTutum}
                  alt="Okula yönelik tutum ölçeği"
                />
                <div className="py-4">
                  <h4 style={{ textAlign: "center" }}>
                    Okula yönelik tutum ölçeği
                  </h4>
                </div>
              </div>
            </a>
          </div>
          <div className="col">
            <a href="/olcek/9">
              <div>
                <img
                  className="rounded img-fluid d-block w-100 fit-cover"
                  style={{ height: "200px" }}
                  src={OkulAidiyet}
                  alt="Okul aidiyet ölçeği"
                />
                <div className="py-4">
                  <h4 style={{ textAlign: "center" }}>Okul aidiyet ölçeği</h4>
                </div>
              </div>
            </a>
          </div>
          <div className="col">
            <a href="/olcek/10">
              <div>
                <img
                  className="rounded img-fluid d-block w-100 fit-cover"
                  style={{ height: "200px" }}
                  src={GencIcsellestirilmis}
                  alt="Genç içselleştirilmiş davranış ölçeği"
                />
                <div className="py-4">
                  <h4 style={{ textAlign: "center" }}>
                    Genç içselleştirilmiş davranış ölçeği
                  </h4>
                </div>
              </div>
            </a>
          </div>
          <div className="col">
            <a href="/olcek/11">
              <div>
                <img
                  className="rounded img-fluid d-block w-100 fit-cover"
                  style={{ height: "200px" }}
                  src={GencDissallastirilmis}
                  alt="Genç dışsallaştırılmış davranış ölçeği"
                />
                <div className="py-4">
                  <h4 style={{ textAlign: "center" }}>
                    Genç dışsallaştırılmış davranış ölçeği
                  </h4>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SosyalDuygusalGelisim;
